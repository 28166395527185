<script lang="ts" setup>
import type { SwiperContainer } from "swiper/element";
import type { Swiper, SwiperOptions } from "swiper/types";

withDefaults(defineProps<{
  items: any[];
  navPosition?: "center-outer" | "bottom-right";
} & SwiperOptions>(), {
  navPosition: "center-outer",
  slidesPerView: 1,
  spaceBetween: 16,
  centeredSlides: true,
  loop: false,
  autoplay: true,
});

const emit = defineEmits(["slideChange"]);

const swiperContainer = useTemplateRef<SwiperContainer>("swiper-container");
const activeSlideIndex = ref<number>(0);

const canSlideToPrev = ref<boolean>(true);
const canSlideToNext = ref<boolean>(true);

function slideToPrev() {
  swiperContainer.value!.swiper.slidePrev();
}

function slideToNext() {
  swiperContainer.value!.swiper.slideNext();
}

function updateRefs(swiper: Swiper) {
  // There are possibly multiple slides active at the same time when slidesPerView > 1.
  activeSlideIndex.value = swiper.activeIndex;
  canSlideToPrev.value = !(swiper.isBeginning ?? false);
  canSlideToNext.value = !(swiper.isEnd ?? false);
}

onMounted(async () => {
  swiperContainer.value?.swiper.on("slideChange", (swiper: Swiper) => {
    updateRefs(swiper);
    emit("slideChange", activeSlideIndex.value);
  });
  updateRefs(swiperContainer.value!.swiper);
});
</script>

<template>
  <div class="relative h-full">
    <swiper-container
      ref="swiper-container"
      class="h-full"
      :data-nav-position="navPosition"
      :slides-per-view="slidesPerView"
      :space-between="spaceBetween"
      :centered-slides="centeredSlides"
      :direction="direction"
      :navigation="{}"
      :loop="loop"
      :autoplay="autoplay"
      :autoplay-delay="5000"
      :autoplay-disable-on-interaction="true"
    >
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <div slot="container-start">
        <IconCircle icon="i-heroicons-arrow-long-left" role="button" :disabled="!canSlideToPrev" @click="slideToPrev" />
      </div>
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <div slot="container-end">
        <IconCircle icon="i-heroicons-arrow-long-right" role="button" :disabled="!canSlideToNext" @click="slideToNext" />
      </div>
      <swiper-slide v-for="(item, index) in items" :key="index" :data-slide-index="index" class="flex">
        <slot :item="item" :index="index" />
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<style scoped>
swiper-container::part(button-prev),
swiper-container::part(button-next) {
  display: none;
}

swiper-container {
  --size-swiper-nav-spacing: 1.5rem;
}

[slot="container-start"],
[slot="container-end"] {
  position: absolute;
  z-index: 30;
}

[data-nav-position="center-outer"] {
  [slot="container-start"],
  [slot="container-end"] {
    top: calc(50% - (var(--size-icon-circle) / 2));
  }

  [slot="container-start"] {
    left: var(--size-swiper-nav-spacing);
  }

  [slot="container-end"] {
    right: var(--size-swiper-nav-spacing);
  }
}

[data-nav-position="bottom-right"] {
  [slot="container-start"],
  [slot="container-end"] {
    bottom: var(--size-swiper-nav-spacing);
  }

  [slot="container-start"] {
    right: calc(var(--size-swiper-nav-spacing) + 0.5rem + var(--size-icon-circle));
  }

  [slot="container-end"] {
    right: var(--size-swiper-nav-spacing);
  }
}
</style>
