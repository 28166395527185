<script lang="ts" setup>
import type { AccordionItem } from "#ui/types";
import type { LinkItem } from "~~/models/links";
import type { TechnicalDetailsSectionRecord } from "~~/server/graphql/datocms/generated";
import { Image as DatocmsImage } from "vue-datocms";

const { data } = defineProps<{ data: TechnicalDetailsSectionRecord }>();

const localePath = useLocalePath();

const technicalDetails = computed<AccordionItem[]>(() => {
  return data.technicalDetails.map((technicalDetail) => {
    return {
      label: technicalDetail.title,
      content: technicalDetail.info,
    } satisfies AccordionItem;
  });
});

const downloadLink = computed<LinkItem>(() => {
  return getLinkForType(data.downloadLink, localePath);
});
</script>

<template>
  <div class="bg-primary py-[56px] text-white" :class="{ 'lg:pt-3xl': data.sectionTitle }">
    <UContainer>
      <SectionTitle as="span" :label="data.sectionTitle" />
      <div class="gap-xl flex flex-col md:flex-row md:gap-[72px]">
        <div class="pt-md flex-1 md:order-1">
          <h3 class="mb-4xl uppercase text-inherit" v-html="data.title" />
          <UAccordion
            color="white"
            variant="link"
            size="lg"
            :items="technicalDetails"
            class="mb-2xl text-inherit"
            :ui="{ container: 'py-md border-t border-white [&:not([data-headlessui-state=open])]:opacity-40 transition-opacity' }"
          >
            <template #default="{ item, open }">
              <UButton
                color="white"
                variant="link"
                class="!px-0 py-0 hover:no-underline"
              >
                <span class="truncate">{{ item.label }}</span>

                <template #trailing>
                  <IconCircle class="ms-auto">
                    <UIcon
                      name="i-ph-minus"
                      class="text-primary size-[18px]"
                    />
                    <UIcon
                      name="i-ph-minus"
                      class="text-primary absolute size-[18px] rotate-90 opacity-100 transition-all duration-200"
                      :class="[open && '!rotate-0 !opacity-0']"
                    />
                  </IconCircle>
                </template>
              </UButton>
            </template>

            <template #item="{ item }">
              <div class="pt-xs lg:w-3/4 [&>p:last-child]:mb-0" v-html="item.content" />
            </template>
          </UAccordion>
          <UButton :to="downloadLink.href!" color="white" variant="ghost" icon="i-ph-download-simple" class="border-b-gradient-white">
            {{ downloadLink.label }}
          </UButton>
        </div>
        <div class="md:py-2xl flex flex-1 items-center ">
          <DatocmsImage v-if="data.image" :data="data.image.responsiveImage" class="h-full" object-fit="cover" />
        </div>
      </div>
    </UContainer>
  </div>
</template>
