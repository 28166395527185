<script lang="ts" setup>
import type { ContactTeasersSectionRecord, TeaserRecord } from "~~/server/graphql/datocms/generated";
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";

defineProps<{ data: ContactTeasersSectionRecord }>();

const localePath = useLocalePath();

function getTeaserUrl(teaser: TeaserRecord) {
  return getLinkForType(teaser.link, localePath);
}
</script>

<template>
  <div>
    <UContainer class="py-3xl">
      <div class="gap-lg md:gap-xl flex flex-col">
        <SectionTitle as="h2" :label="data.sectionTitle" color="black" />

        <div class="gap-3xl md:gap-4xl grid grid-cols-1 md:grid-cols-2">
          <div
            v-for="teaser of data.teasers"
            :key="teaser.id"
            class="gap-md flex flex-col"
          >
            <DatocmsImage
              :data="teaser.image.responsiveImage as ResponsiveImageType"
              class="h-[289px]"
              object-fit="cover"
              object-position="center"
            />

            <div class="p-xs gap-md md:p-lg flex flex-col items-start">
              <div class="text-base font-bold italic md:text-lg [&>p:last-child]:mb-0" v-html="teaser.title" />
              <div v-if="teaser.optText" class="[&>p:last-child]:mb-0" v-html="teaser.optText" />

              <UButton
                variant="ghost" icon="i-ph-arrow-right" trailing
                color="black"
                class="border-b-gradient-black"
                :to="getTeaserUrl(teaser).href ?? '/'"
                :target="getTeaserUrl(teaser).target ?? '_self'"
              >
                {{ teaser.link.text }}
              </UButton>
            </div>
          </div>
        </div>
      </div>
    </UContainer>
  </div>
</template>

<style scoped>

</style>
